import {
  AdditiveBlending,
  BoxGeometry,
  CircleGeometry,
  Color,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  ShaderMaterial,
  Vector3,
} from 'three'
import Stage from './Stage'
import { Text } from 'troika-three-text'
import font from './IBMPlexMono-Regular.woff'
import { COLORS, State } from './App'
import { elevationFactor, Item, MAP_CENTER, MAX_ALTITUDE, MIN_ALTITUDE, TILE_SIZE } from './Planet'
import { Easing, Group, Tween } from '@tweenjs/tween.js'
import vertexShader from './box.vert'
import fragmentShader from './box.frag'

const FONT_SIZE = 0.04

export class Label {
  private readonly material = new MeshBasicMaterial({
    color: COLORS.lightBlue,
    depthTest: false,
    blending: AdditiveBlending,
  })
  private readonly line: Mesh<PlaneGeometry, MeshBasicMaterial>
  private readonly circle: Mesh<CircleGeometry, MeshBasicMaterial>
  private readonly inner = new Object3D()
  private readonly text: Text
  private readonly group = new Group()
  private readonly box?: Mesh<BoxGeometry, ShaderMaterial>
  private readonly object: Object3D

  private elapsed = 0
  private needsUpdate = false
  private isVisible: boolean

  constructor(
    private readonly stage: Stage,
    parent: Object3D,
    item: Item,
    private readonly index: number,
    state: State,
  ) {
    this.isVisible = state === 'map'

    const { coordinates, label, subline, showBox = false, length } = item

    const position = new Vector3(
      ((coordinates[0] - MAP_CENTER[0]) / TILE_SIZE) * 2,
      ((coordinates[2] - MIN_ALTITUDE) / (MAX_ALTITUDE - MIN_ALTITUDE)) * elevationFactor,
      ((coordinates[1] - MAP_CENTER[1]) / TILE_SIZE) * -2,
    )

    this.object = new Object3D()
    this.object.position.copy(position)
    this.object.add(this.inner)
    this.object.visible = this.isVisible

    this.circle = new Mesh(new CircleGeometry(0.007, 16), this.material)
    this.inner.add(this.circle)

    this.line = new Mesh(new PlaneGeometry(0.003, length), this.material)
    this.line.geometry.translate(0, length * 0.5, 0)
    this.inner.add(this.line)

    this.text = new Text()
    this.text.font = font
    this.text.text = `${label}\n${subline}`
    this.text.fontSize = FONT_SIZE
    this.text.position.y = length + 0.01
    this.text.colorRanges = { 0: COLORS.lightBlue, [label.length]: COLORS.blue }
    this.text.anchorX = '50%'
    this.text.anchorY = '100%'
    this.text.sync()

    this.inner.add(this.text)

    if (showBox) {
      const geometry = new BoxGeometry(0.05, 0.05, 0.05)
      const material = new ShaderMaterial({
        transparent: true,
        depthTest: false,
        blending: AdditiveBlending,
        uniforms: {
          uSize: {
            value: new Vector3(
              geometry.parameters.width,
              geometry.parameters.height,
              geometry.parameters.depth,
            ).multiplyScalar(0.5),
          },
          uColor: { value: new Color(COLORS.lightBlue) },
        },
        vertexShader,
        fragmentShader,
      })

      this.box = new Mesh(geometry, material)
      this.object.add(this.box)
    }

    parent.add(this.object)
  }

  show() {
    if (this.isVisible) return
    this.isVisible = true

    this.group.removeAll()

    new Tween({ elapsed: this.elapsed }, this.group)
      .to({ elapsed: 1 }, 1250)
      .delay(750 + this.index * 250)
      .easing(Easing.Quartic.Out)
      .onStart(() => (this.object.visible = true))
      .onUpdate(({ elapsed }) => {
        this.elapsed = elapsed
        this.needsUpdate = true
      })
      .start()
  }

  hide() {
    if (!this.isVisible) return
    this.isVisible = false

    this.group.removeAll()

    new Tween({ elapsed: this.elapsed }, this.group)
      .to({ elapsed: 0 }, 250)
      .delay(this.index * 50)
      .easing(Easing.Sinusoidal.In)
      .onUpdate(({ elapsed }) => {
        this.elapsed = elapsed
        this.needsUpdate = true
      })
      .onComplete(() => (this.object.visible = false))
      .start()
  }

  update(time: number) {
    this.group.update(time)
    this.inner.quaternion.copy(this.stage.camera.quaternion)
    if (this.needsUpdate) {
      this.needsUpdate = false
      this.line.scale.y = this.elapsed
      this.circle.scale.set(this.elapsed, this.elapsed, this.elapsed)
      this.text.material.opacity = this.elapsed
      this.box?.scale.set(this.elapsed, this.elapsed, this.elapsed)
    }
  }
}
