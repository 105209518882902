import { Vector2 } from 'three'

const v1 = new Vector2()
const v2 = new Vector2()

export default function getDistance2d(p1: PointerEvent, p2: PointerEvent): number {
  v1.set(p1.x, p1.y)
  v2.set(p2.x, p2.y)
  return v1.distanceTo(v2)
}
