import { Clock, ImageLoader, LinearSRGBColorSpace, PerspectiveCamera, Scene, TextureLoader, WebGLRenderer } from 'three'
import image from './image.png'
import particle from './particle.png'
import Planet from './Planet'
import Stars from './Stars'
import elevation from './elevation.png'
import styles from './Page.module.css'
import Controls from './Controls'
import { Gallery } from './Gallery'
import App, { State } from './App'
import { preloadFont } from 'troika-three-text'
import plexMono from './IBMPlexMono-Regular.woff'
import newsGothic from './NewsGothic-Bold.woff'
import { CrawlText } from './CrawlText'
import Dolly from './Dolly'

const PARAMS = {
  color: '#00b2ff',
}

export default class Stage {
  private readonly crawlText: CrawlText
  private stars?: Stars
  private gallery: Gallery

  public readonly controls: Controls
  public readonly dolly: Dolly
  public readonly renderer: WebGLRenderer
  public readonly scene = new Scene()
  public readonly camera = new PerspectiveCamera()
  public readonly container: HTMLElement
  public readonly mq = window.matchMedia('(min-width:1024px)')
  public readonly clock = new Clock()

  public planet?: Planet
  public aspect = 1
  public vFov = 1
  public hFov = 1
  public width = 0
  public height = 0

  constructor(
    state: State,
    public readonly app: App,
  ) {
    this.resize = this.resize.bind(this)
    this.update = this.update.bind(this)

    this.container = document.querySelector(`.${styles.Stage}`) as HTMLElement
    this.renderer = new WebGLRenderer({ antialias: true })
    this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio))
    this.renderer.outputColorSpace = LinearSRGBColorSpace

    this.container.appendChild(this.renderer.domElement)

    this.camera.near = 0.01
    this.camera.far = 2000
    this.scene.add(this.camera)

    const { width, height } = this.container.getBoundingClientRect()
    this.width = width
    this.height = height

    this.controls = new Controls(this)
    this.dolly = new Dolly(this, state)
    this.gallery = new Gallery(this, state)
    this.crawlText = new CrawlText(this, state)

    this.resize()
    window.addEventListener('resize', this.resize)
    this.renderer.setAnimationLoop(this.update)

    this.init(state)
  }

  async init(state: State) {
    const [logoImage, particleTexture, elevationTexture] = await Promise.all([
      new ImageLoader().loadAsync(image),
      new TextureLoader().loadAsync(particle),
      new TextureLoader().loadAsync(elevation),
      this.preloadFont(plexMono),
      this.preloadFont(newsGothic),
    ])

    this.stars = new Stars(this, particleTexture)
    this.planet = new Planet(this, logoImage, particleTexture, elevationTexture, state)

    this.stars.setColor(PARAMS.color)
    this.planet?.setColor(PARAMS.color)
  }

  async preloadFont(font: string) {
    return new Promise((resolve) => {
      preloadFont(
        {
          font,
          characters: 'abcdefghijklmnopqrstuvwxyzäüöABCDEFGHIJKLMNOPQRSTUVWXYZÄÜÖ123456789/[]{},.:°-_',
        },
        resolve,
      )
    })
  }

  navigate(state: State) {
    this.planet?.navigate(state)
    this.dolly.navigate(state)

    switch (state) {
      case 'planet': {
        this.gallery.hide()
        this.crawlText.hide()
        break
      }
      case 'map': {
        this.gallery.hide()
        this.crawlText.hide()
        break
      }
      case 'text': {
        this.gallery.hide()
        this.crawlText.show()
        break
      }
      case 'gallery': {
        this.gallery.show()
        this.crawlText.hide()
        break
      }
      case 'closeup': {
        this.gallery.hide()
        this.crawlText.hide()
        break
      }
    }
  }

  resize() {
    const { width, height } = this.container.getBoundingClientRect()
    this.width = width
    this.height = height

    this.aspect = this.width / this.height
    this.camera.aspect = this.aspect
    this.camera.updateProjectionMatrix()

    this.vFov = this.camera.getFilmHeight() / this.camera.getFocalLength()
    this.hFov = this.vFov * this.camera.aspect

    this.crawlText.resize()
    this.gallery.resize()
    this.planet?.resize()
    this.stars?.resize()

    this.renderer.setSize(width, height)
  }

  update(time: number) {
    this.renderer.setAnimationLoop(this.update)
    const delta = this.clock.getDelta()

    this.controls.update()
    this.dolly.update(delta)
    this.stars?.update(time)
    this.planet?.update(time, delta)
    this.gallery.update(time, delta)
    this.crawlText.update(time)

    this.renderer.render(this.scene, this.camera)
  }
}
