import Stage from './Stage'
import { MathUtils, Object3D } from 'three'
import { GalleryItem } from './GalleryItem'
import { State } from './App'

export class Gallery {
  private readonly object = new Object3D()
  private readonly inner = new Object3D()
  private items: GalleryItem[]
  private maxRotation = Infinity
  private rotation = 0
  private rotationStart = 0

  constructor(
    private readonly stage: Stage,
    state: State,
  ) {
    const items = window.__GALLERY__

    this.object.add(this.inner)
    this.stage.scene.add(this.object)

    this.items = items.map((data) => new GalleryItem(data, this.inner, state, stage))
    this.stage.controls.on('start', this.onPointerStart.bind(this))
  }

  onPointerStart() {
    this.rotationStart = this.rotation
  }

  show() {
    this.items.forEach((item) => item.show())
  }

  async hide() {
    this.items.forEach((item) => item.hide())
  }

  resize() {
    const cameraDistance = 0.1
    const { vFov, aspect } = this.stage
    const fov = vFov * cameraDistance
    const scaleY = fov * 0.7
    const scaleX = fov * aspect * 0.85
    const scale = Math.min(scaleX, scaleY)
    this.maxRotation = Math.floor(this.items.length * 0.5) * scale * 1.1

    this.items.forEach((item, index) => item.resize(scale, index * scale * 1.1 - this.maxRotation))
  }

  update(time: number, delta: number) {
    const {
      controls: { pointerDelta, velocity, dragging, zoom },
    } = this.stage

    const zoomOffset = 1 - zoom
    const scale = 1 - zoomOffset * 0.04
    this.object.scale.set(scale, scale, scale)

    if (dragging) {
      this.rotation = this.rotationStart + pointerDelta.x * 0.0005
    } else {
      this.rotation += velocity.x * 0.0005
    }
    this.rotation = MathUtils.clamp(this.rotation, this.maxRotation * -1, this.maxRotation)

    this.object.rotation.y += (this.object.rotation.y - this.rotation) * -0.1

    this.items.forEach((item) => item.update(delta))
  }
}
