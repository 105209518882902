import Stage from './Stage'
import { Router } from './Router'
import { ShortLang } from './lib/primsic'
import { PageDocument } from './@types/types.generated'
import navigationStyles from './Navigation.module.css'
import { NavigationController } from './NavigationController'
import { getScrollbarWidth } from './getScrollbarWidth'

export enum COLORS {
  blue = '#506CFF',
  darkBlue = '#233CBE',
  lightBlue = '#C6E4F8',
  yellow = '#F5C91C',
}

export interface Controller {
  dispose?(): void

  resize?(): void

  show?(): Promise<void>

  hide?(): Promise<void>

  update?(time: number): void

  load?(): Promise<void>

  scroll?(scrollY: number): void
}

export type State = PageDocument['data']['state']

export default class App {
  private readonly parser: DOMParser = new DOMParser()
  private readonly stage: Stage

  private controllers: Controller[] = []
  private main?: HTMLElement

  public lang: ShortLang
  public readonly router: Router

  constructor() {
    this.update = this.update.bind(this)
    this.lang = document.documentElement.lang as ShortLang

    //remove hash on load
    history.replaceState('', document.title, `${window.location.pathname}${window.location.search}`)

    const scrollbarWidth = getScrollbarWidth()
    document.body.style.setProperty('--scrollbar', `${scrollbarWidth}px`)

    this.main = document.querySelector('main') as HTMLElement
    this.stage = new Stage(this.main.dataset.state as State, this)
    this.router = new Router()
    this.router.on('change', this.navigate.bind(this))
    document.addEventListener('click', this.onDocumentClick.bind(this))
    requestAnimationFrame(this.update)

    this.init()
  }

  onDocumentClick(event: MouseEvent): void {
    let target: HTMLElement = event.target as HTMLElement

    while (target && target.parentNode) {
      if (target.tagName === 'A') {
        const { origin, pathname, hash } = new URL((target as HTMLAnchorElement).href)
        if (origin === window.location.origin && !(hash && pathname === window.location.pathname)) {
          event.preventDefault()
          this.router.push(pathname)
        }
        break
      }
      target = target.parentNode as HTMLElement
    }
  }

  async init() {
    this.setControllers()
    await Promise.all([...this.controllers.map((controller) => controller.load?.())])
    this.controllers.forEach((controller) => controller.show?.())
  }

  async navigate() {
    const [html] = await Promise.all([
      this.loadPage(),
      ...this.controllers.map((controller) => controller.hide?.()).filter(Boolean),
    ])

    this.controllers.forEach((controller) => controller.dispose?.())

    const doc = this.parser.parseFromString(html, 'text/html')
    const main = doc.querySelector('main') as HTMLElement

    document.title = doc.title
    document.documentElement.lang = doc.documentElement.lang

    this.lang = doc.documentElement.lang as ShortLang

    window.scrollTo(0, 0)

    this.main?.replaceWith(main)
    this.main = main

    this.stage.navigate(main.dataset.state as State)

    this.setControllers()
    await Promise.all([...this.controllers.map((controller) => controller.load?.())])
    this.controllers.forEach((controller) => controller.show?.())
  }

  async loadPage(): Promise<string> {
    const response = await fetch(`${this.router.activeRoute}/index.html`)
    return await response.text()
  }

  setControllers(): void {
    this.controllers = [...this.getNodes(navigationStyles.Main).map((node) => new NavigationController(node))]
  }

  getNodes(className: string): HTMLElement[] {
    return Array.from(document.body?.querySelectorAll(`.${className}`) as NodeListOf<HTMLElement>)
  }

  update(time: number): void {
    requestAnimationFrame(this.update)
    this.controllers.forEach((controller) => controller.update?.(time))
  }
}
