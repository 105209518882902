import Stage from './Stage'
import { Color, MathUtils, Object3D, ShaderMaterial, Vector3 } from 'three'
import { Text } from 'troika-three-text'
import font from './NewsGothic-Bold.woff'
import { COLORS, State } from './App'

const DELTA_MODIFIER = 40
const LINE_HEIGHT = 1.4
const MAX_WIDTH = 4
const ANGLE = 45
const CAM_DISTANCE = 5

export class CrawlText {
  private readonly object = new Object3D()
  private readonly rotation = new Object3D()
  private readonly inner = new Object3D()
  private readonly container = new Object3D()
  private readonly end = new Object3D()
  private readonly endPos = new Vector3()
  private readonly text: Text
  private readonly title: Text
  private readonly eyebrow: Text
  private isVisible = false
  private textHeight: number | null = null
  private y = 0
  private yStart = 0
  private yMax = 0
  private scale = 1

  constructor(
    private readonly stage: Stage,
    state: State,
  ) {
    const { eyebrow, title, text } = window.__CRAWL_TEXT__

    const material = new ShaderMaterial({
      uniforms: {
        uColor: { value: new Color(COLORS.yellow).convertLinearToSRGB() },
        uBottom: { value: this.stage.mq.matches ? -1 : -0.8 },
      },
      //language=GLSL
      vertexShader: `
      uniform float uBottom;
      varying float vOpacity;
      void main() {
          vec4 mvPosition = modelViewMatrix * vec4(position, 1);
          vec4 pos = projectionMatrix * mvPosition;
          vec2 ndc =  pos.xy/pos.w;
          float depth = smoothstep(4.0, 6.5, -mvPosition.z);
          float bottom = smoothstep(uBottom + 0.7, uBottom, ndc.y);
          vOpacity = 1.0 - max(depth, bottom);
          gl_Position = pos;
      }
      `,
      //language=GLSL
      fragmentShader: `
      uniform vec3 uColor;
      varying float vOpacity;
      void main() {
          gl_FragColor = vec4(uColor, vOpacity);
          if (gl_FragColor.a <= 0.0) discard;
      }
      `,
    })

    this.inner.add(this.container)
    this.container.add(this.end)
    this.rotation.add(this.inner)
    this.object.add(this.rotation)
    this.stage.scene.add(this.object)

    this.rotation.rotation.x = MathUtils.degToRad(360 - ANGLE)

    this.object.visible = false

    this.eyebrow = new Text()
    this.eyebrow.font = font
    this.eyebrow.text = eyebrow
    this.eyebrow.textAlign = 'center'
    this.eyebrow.lineHeight = LINE_HEIGHT
    this.eyebrow.fontSize = 0.15
    this.eyebrow.anchorX = '50%'
    this.eyebrow.anchorY = 0
    this.eyebrow.material = material
    this.eyebrow.transparent = true
    this.eyebrow.depthTest = false

    this.container.add(this.eyebrow)

    this.title = new Text()
    this.title.font = font
    this.title.text = title
    this.title.textAlign = 'center'
    this.title.lineHeight = LINE_HEIGHT
    this.title.fontSize = 0.25
    this.title.anchorX = '50%'
    this.title.anchorY = 0
    this.title.position.y = 0.15 * LINE_HEIGHT * -1
    this.title.material = material
    this.title.transparent = true
    this.title.depthTest = false

    this.container.add(this.title)

    this.text = new Text()
    this.text.font = font
    this.text.text = text
    this.text.textAlign = 'justify'
    this.text.lineHeight = LINE_HEIGHT
    this.text.fontSize = 0.25
    this.text.anchorX = '50%'
    this.text.anchorY = 0
    this.text.maxWidth = MAX_WIDTH
    this.text.position.y = 0.65 * LINE_HEIGHT * -1
    this.text.material = material
    this.text.transparent = true
    this.text.depthTest = false

    this.container.add(this.text)

    this.eyebrow.sync()
    this.title.sync()
    this.text.sync()

    this.text.addEventListener('synccomplete', () => {
      const size = this.text.geometry.boundingBox.getSize(new Vector3())
      this.textHeight = size.y + 2 * LINE_HEIGHT
      this.end.position.y = (this.textHeight || 0) * -1

      if (state === 'text') {
        this.show()
      }
    })

    this.stage.controls.on('wheel', this.onWheel.bind(this))
    this.stage.controls.on('start', this.onPointerStart.bind(this))
  }

  onPointerStart() {
    this.yStart = this.y
  }

  onWheel(event: WheelEvent) {
    const delta = event.deltaMode === WheelEvent.DOM_DELTA_LINE ? event.deltaY * DELTA_MODIFIER : event.deltaY
    this.y += delta * 0.001
  }

  show() {
    if (this.isVisible) return
    this.isVisible = true

    this.resize()

    // ASA Triangle
    const A = MathUtils.degToRad(this.stage.camera.fov * 0.5)
    const B = MathUtils.degToRad(ANGLE)
    const C = Math.PI - A - B

    const a = Math.sin(A) * (CAM_DISTANCE / Math.sin(C))

    this.yMax = a * -1
    this.y = this.yMax
    this.inner.position.y = this.y
    this.object.visible = true
  }

  hide() {
    if (!this.isVisible) return
    this.isVisible = false

    this.object.visible = false
  }

  resize() {
    const hFov = this.stage.hFov * CAM_DISTANCE
    this.scale = (hFov / MAX_WIDTH) * (this.stage.mq.matches ? 0.5 : 0.75)
    this.container.scale.set(this.scale, this.scale, this.scale)
  }

  update(time: number) {
    if (!this.isVisible) return

    const {
      controls: { pointerDelta, velocity, dragging },
    } = this.stage

    this.object.quaternion.copy(this.stage.camera.quaternion)

    if (dragging) {
      this.y = this.yStart + pointerDelta.y * -0.01
    } else {
      this.y += 0.01
    }

    this.y += velocity.y * -0.005

    this.y = Math.max(this.y, this.yMax)
    this.inner.position.y += (this.inner.position.y - this.y) * -0.1

    this.end.getWorldPosition(this.endPos)

    if (this.textHeight !== null && this.endPos.y >= 0) {
      this.stage.app.router.push(`/${this.stage.app.lang}`)
    }
  }
}
