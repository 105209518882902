#define GLSLIFY 1
uniform vec3 uSize;
uniform vec3 uColor;

varying vec3 vPos;

void main() {
    float thickness = 0.003;
    float smoothness = 0.0005;

    vec3 color = vec3(1.0);
    float a = smoothstep(thickness, thickness + smoothness, length(abs(vPos.xy) - uSize.xy));
    a *= smoothstep(thickness, thickness + smoothness, length(abs(vPos.yz) - uSize.yz));
    a *= smoothstep(thickness, thickness + smoothness, length(abs(vPos.xz) - uSize.xz));

    gl_FragColor = vec4(uColor, 1.0 - a);
}