@keyframes AnimInLink {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes AnimInLeft {
  0% {
    transform: translateX(1rem);
    opacity: 0.75;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes AnimInRight {
  0% {
    transform: translateX(-1rem);
    opacity: 0.75;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes AnimInDown {
  0% {
    transform: translateY(-1rem);
    opacity: 0.75;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes AnimInBg {
  0% {
    transform: scale(0.9);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.Main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  justify-content: center;
  justify-items: center;
  align-items: end;
  align-content: end;
  grid-template: minmax(0, 1fr) / minmax(0, 1fr);
  display: grid;
  padding: 10vw calc(10vw + var(--scrollbar)) 10vw 10vw;
  box-sizing: border-box;
  color: var(--lightBlue);
}

.Open {
  pointer-events: auto;
}

.Inner {
  position: relative;
  grid-row: 1;
  grid-column: 1;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 1rem;
  padding: 1.5rem 1.75rem;
}

.Bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  top: 0;
  left: 0;
  border-radius: 1rem;
  opacity: 0;
  animation-duration: 750ms;
  animation-timing-function: var(--ease-out-expo);
  transform-origin: 50% 75%;
}

.Open .Bg {
  opacity: 1;
  animation-name: AnimInBg;
}

.Links {
  position: relative;
  gap: 1rem;
  display: none;
}

.Open .Links {
  display: grid;
}

.Link {
  --delay: 0;
  text-align: center;
  padding: 0.75rem 2rem;
  text-transform: uppercase;
  text-decoration: none;
  border: solid 1px currentColor;
  border-radius: 2rem;
  animation-fill-mode: both;
  animation-delay: var(--delay);
  animation-duration: 1000ms;
  animation-timing-function: var(--ease-out-expo);
  transition:
    box-shadow 250ms ease-in-out,
    background-color 250ms linear,
    color 250ms linear;
}

.Link:hover {
  box-shadow: 0 0 1.5em var(--lightBlue);
}

.Link:active,
.Link.Active {
  color: var(--blue);
  background-color: var(--lightBlue);
}

.Link.Active {
  pointer-events: none;
}

.Open .Link {
  animation-name: AnimInLink;
}

.Row {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content minmax(0, 1fr);
  align-items: center;
  padding: 1.5rem 0 0 0;
  gap: 0.5rem;
}

.Languages {
  display: flex;
  opacity: 0;
  align-items: center;
  gap: 0.25rem;
  animation-fill-mode: both;
  animation-delay: var(--delay);
  animation-duration: 1000ms;
  animation-timing-function: var(--ease-out-expo);
}

.Open .Languages {
  animation-name: AnimInLeft;
}

.Current {
  font-size: 0.75rem;
  text-transform: uppercase;
  border: solid 1px currentColor;
  display: block;
  padding: 0.5em;
  border-radius: 0.4em;
}

.Lang {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.Lang:hover {
  color: white;
}

.Button {
  color: var(--lightBlue);
  display: flex;
  gap: 0.25rem;
  align-items: center;
  border: 1px solid var(--lightBlue);
  border-radius: 2rem;
  height: 2.75rem;
  padding: 0 1rem 0 0.55rem;
  background: transparent;
  text-transform: uppercase;
  transition:
    box-shadow 250ms ease-in-out,
    background-color 250ms linear,
    color 250ms linear;
  pointer-events: auto;
}

.Button:hover {
  box-shadow: 0 0 1.5em var(--lightBlue);
}

.Button:active {
  color: var(--blue);
  background-color: var(--lightBlue);
}

.Open .Button {
  color: var(--blue);
  background-color: var(--lightBlue);
  padding: 0 1rem 0 0.75rem;
}

.Home {
  display: grid;
  align-items: center;
  height: 1.75rem;
  border-right: solid 1px currentColor;
  padding-right: 0.75rem;
}

.Home:hover {
  filter: brightness(1.4);
}

.Home img {
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  transition: mix-blend-mode 250ms linear;
  mix-blend-mode: screen;
}

.Open .Home {
  display: none;
}

.Button:active .Home {
  mix-blend-mode: color-burn;
}

.ButtonInner {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0 0 0 0.35rem;
}

.ButtonInner:hover {
  color: white;
}

.Open .ButtonInner:hover {
  color: var(--blue);
}

.Icon {
  position: relative;
  aspect-ratio: 12/8;
  stroke: currentColor;
  stroke-width: 2px;
  fill: none;
  width: 0.75rem;
  height: 0.75rem;
}

.Icon svg {
  position: absolute;
  left: 0;
  top: 0;
  aspect-ratio: 12/8;
  width: 0.75rem;
  transition:
    transform 350ms var(--ease-inout-cubic),
    opacity 150ms linear;
}

.Icon > :first-child {
  opacity: 0;
  transform: translateY(-0.125rem) rotate(180deg);
}

.Icon > :last-child {
  transform: translateY(0);
}

.Open .Icon > :first-child {
  opacity: 1;
  transform: translateY(0) rotate(180deg);
}

.Open .Icon > :last-child {
  transform: translateY(0.25rem);
}

.Instagram {
  opacity: 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  white-space: nowrap;
  animation-fill-mode: both;
  animation-delay: var(--delay);
  animation-duration: 1000ms;
  animation-timing-function: var(--ease-out-expo);
  transition: color 250ms linear;
}

.Instagram:hover {
  color: white;
}

.Open .Instagram {
  animation-name: AnimInRight;
}

.Legal {
  opacity: 0;
  position: relative;
  font-size: 0.75rem;
  text-transform: uppercase;
  justify-self: center;
  animation-fill-mode: both;
  animation-delay: var(--delay);
  animation-duration: 1000ms;
  animation-timing-function: var(--ease-out-expo);
  transition: color 250ms linear;
}

.Legal:hover {
  color: white;
}

.Open .Legal {
  animation-name: AnimInDown;
}

@media (min-width: 1024px) {
  .Main {
    padding: 2vw calc(4vw + var(--scrollbar)) 2vw 4vw;
  }
}
