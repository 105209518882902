import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  MathUtils,
  Points,
  ShaderMaterial,
  Texture,
  Vector3,
} from 'three'

import Stage from './Stage'
import vertexShader from './stars.vert'
import fragmentShader from './stars.frag'

export default class Stars {
  private readonly stars: Points<BufferGeometry, ShaderMaterial>

  constructor(
    private readonly stage: Stage,
    particleTexture: Texture,
  ) {
    const positions = []
    const temp = new Vector3()

    const phi = Math.PI * (Math.sqrt(5) - 1)

    const fibonacci = (target: Vector3, index: number, count: number) => {
      const y = 1 - (index / (count - 1)) * 2
      const radius = Math.sqrt(1 - y * y)

      const theta = phi * index

      const x = Math.cos(theta) * radius
      const z = Math.sin(theta) * radius

      target.x = x
      target.y = y
      target.z = z
    }

    for (let i = 0; i < 20_000; i++) {
      fibonacci(temp, i, 20_000)
      temp.multiplyScalar(MathUtils.randFloat(20, 200))

      positions.push(temp.x + MathUtils.randFloat(-5, 5))
      positions.push(temp.y + MathUtils.randFloat(-5, 5))
      positions.push(temp.z + MathUtils.randFloat(-5, 5))
    }

    const indices = [...new Array(positions.length).keys()]
    const geometry = new BufferGeometry()
    geometry.setAttribute('position', new Float32BufferAttribute(positions, 3))
    geometry.setAttribute('index', new Float32BufferAttribute(indices, 1))

    const material = new ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uScale: { value: this.stage.height * 0.5 },
        uPointScale: { value: this.stage.renderer.getPixelRatio() },
        uTime: { value: performance.now() },
        uTexture: { value: particleTexture },
        uColor: { value: new Color('red') },
      },
      blending: AdditiveBlending,
      transparent: true,
      depthWrite: false,
      depthTest: false,
    })

    this.stars = new Points(geometry, material)
    this.stage.scene.add(this.stars)
  }

  setColor(color: string) {
    this.stars.material.uniforms.uColor.value.setStyle(color)
  }

  resize() {
    this.stars.material.uniforms.uScale.value = this.stage.height * 0.5
  }

  update(time: number) {
    this.stars.material.uniforms.uTime.value = time
  }
}
