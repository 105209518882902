#define GLSLIFY 1
uniform float uScale;
uniform float uTime;
uniform float uPointScale;
uniform vec3 uColor;
attribute float index;
varying vec3 vColor;
varying float vOpacity;

float rand(vec2 co){
    return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
}

vec3 white = vec3(1.0,1.0,1.0);

void main() {
    float random = rand(position.xy);
    float size = 0.35 + random * 0.25;

    vec4 mvPosition = modelViewMatrix * vec4(position, 1);
    vColor = mix(uColor, white, 0.4 + random * 0.6);

    vOpacity = 0.5 + sin(random * 1000.0 + uTime * 0.001) * 0.25 + 0.25;

    gl_PointSize = size * uPointScale;
    gl_PointSize *= ( uScale / - mvPosition.z );

    gl_Position = projectionMatrix * mvPosition;
}