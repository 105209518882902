@font-face {
  font-family: 'Plex';
  src: url('./IBMPlexMono-Regular.woff');
}

@keyframes AnimIn {
  0% {
    transform: translateY(-2rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
  color: var(--blue);
  text-transform: uppercase;
  padding-bottom: 1em;
}

p {
  margin: 0;
}

a {
  color: inherit;
}

button {
  font: inherit;
}

nav {
  position: fixed;
  left: 2vw;
  bottom: 2vw;
}

.Body {
  --blue: #506cff;
  --darkBlue: #233cbe;
  --lightBlue: #c6e4f8;

  --scrollbar: 17px;

  --ease-inout-sine: cubic-bezier(0.37, 0, 0.63, 1);
  --ease-in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
  --ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
  --ease-inout-cubic: cubic-bezier(0.65, 0, 0.35, 1);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-inout-expo: cubic-bezier(0.87, 0, 0.13, 1);
  --ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-inout-back: cubic-bezier(0.68, -0.6, 0.32, 1.6);

  position: relative;
  font-family: Plex, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  color: var(--lightBlue);
}

.Stage {
  position: absolute;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.Stage canvas {
  width: 100%;
  height: 100%;
}

.Container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.Main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.Content {
  display: grid;
  gap: 4vw;
  align-content: start;
  justify-items: start;
  pointer-events: auto;
  animation: AnimIn 1000ms var(--ease-out-expo);
}

.Padding {
  padding: 10vw;
}

.Hidden {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

.Copy {
  max-width: 25rem;
}

.Table {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 0.5rem;
}

.Wide {
  row-gap: 1rem;
}

.Title {
  color: var(--blue);
}

.Label {
  color: var(--blue);
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  gap: 0.5rem;
}

.Line {
  overflow: hidden;
}

.Text {
  text-transform: uppercase;
  justify-self: start;
}

.Blue {
  color: var(--blue);
}

.Button {
  text-transform: uppercase;
  padding: 0.35em 1em 0.35em 0.35em;
  border: solid 1px currentColor;
  border-radius: 2em;
  text-decoration: none;
  color: var(--blue);
  display: flex;
  align-items: center;
  gap: 0.35em;
  justify-self: center;
  pointer-events: auto;
  transition:
    box-shadow 250ms ease-in-out,
    background-color 250ms linear,
    color 250ms linear;
}

.Button:hover {
  box-shadow: 0 0 1.5em var(--lightBlue);
  background-color: var(--lightBlue);
}

.Button:active {
  color: var(--blue);
  background-color: var(--lightBlue);
}

.Icon {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 1.75rem;
  height: 1.75rem;
  color: var(--lightBlue);
  background-color: var(--blue);
  border-radius: 50%;
}

@media (min-width: 1024px) {
  .Padding {
    padding: 4vw;
  }

  .Content {
    width: fit-content;
  }

  .Table {
    grid-template-columns: minmax(0, 20rem) minmax(0, 1fr);
  }
}
