import { Controller } from './App'
import styles from './Navigation.module.css'

export class NavigationController implements Controller {
  private button: HTMLButtonElement
  constructor(private readonly node: HTMLElement) {
    this.onClick = this.onClick.bind(this)
    this.onBgClick = this.onBgClick.bind(this)
    this.button = this.node.querySelector(`.${styles.Button}`) as HTMLButtonElement
    this.button.addEventListener('click', this.onClick)
    this.node.addEventListener('click', this.onBgClick)
  }

  onClick(event: MouseEvent) {
    let anchorClick = false
    let target: HTMLElement = event.target as HTMLElement

    while (target && target.parentNode) {
      if (target.tagName === 'A') {
        anchorClick = true
        break
      }
      target = target.parentNode as HTMLElement
    }

    if (!anchorClick) {
      event.stopPropagation()
    }
    this.node.classList.toggle(styles.Open)
  }

  onBgClick() {
    this.node.classList.remove(styles.Open)
  }

  dispose() {
    this.button.removeEventListener('click', this.onClick)
    this.node.removeEventListener('click', this.onBgClick)
  }
}
