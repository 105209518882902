import {
  BufferAttribute,
  Color,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  ShaderMaterial,
  Shape,
  ShapeGeometry,
  TextureLoader,
  Vector3,
} from 'three'
import { Text } from 'troika-three-text'
import font from './IBMPlexMono-Regular.woff'
import { COLORS, State } from './App'
import { Spring } from './Spring'
import Stage from './Stage'

function getRoundedShape(w: number, h: number, radius: number) {
  const shape = new Shape()

  shape.moveTo(radius, 0)
  shape.lineTo(w - radius, 0)
  shape.quadraticCurveTo(w, 0, w, radius)
  shape.lineTo(w, h - radius)
  shape.quadraticCurveTo(w, h, w - radius, h)
  shape.lineTo(radius, h)
  shape.quadraticCurveTo(0, h, 0, h - radius)
  shape.lineTo(0, radius)
  shape.quadraticCurveTo(0, 0, radius, 0)
  shape.closePath()

  return shape
}

export class GalleryItem {
  private readonly material: MeshBasicMaterial
  private readonly object = new Object3D()
  private readonly inner = new Object3D()
  private readonly textMaterial?: ShaderMaterial | MeshBasicMaterial
  private readonly elapsed: Spring
  private boxMaterial?: MeshBasicMaterial
  private endScale = 1

  constructor(
    data: any,
    parent: Object3D,
    state: State,
    private readonly stage: Stage,
  ) {
    const { url, caption, aspect, subline } = data
    this.object = new Object3D()
    parent.add(this.object)

    this.elapsed = new Spring(state === 'gallery' ? 1 : 0)
    this.object.visible = state === 'gallery'

    this.object.add(this.inner)

    const offset = this.stage.mq.matches ? 0.01 : 0.005
    this.object.rotation.x = MathUtils.randFloat(-1, 1) * offset
    this.inner.position.z = MathUtils.randFloat(0.98, 1.01)

    const a = parseFloat(aspect)
    const w = a > 1 ? 1 / a : 1
    const h = a > 1 ? 1 : a

    const shape = getRoundedShape(w, h, 0.05)
    const geometry = new ShapeGeometry(shape, 8)

    const uv = []
    const position = geometry.getAttribute('position')
    for (let i = 0; i < position.count; i++) {
      const x = position.array[i * 3]
      const y = position.array[i * 3 + 1]
      const u = x / w
      const v = y / h
      uv.push(u)
      uv.push(v)
    }

    geometry.translate(w * -0.5, h * -0.5, 0)
    geometry.setAttribute('uv', new BufferAttribute(new Float32Array(uv), 2))

    this.material = new MeshBasicMaterial({
      map: new TextureLoader().load(url),
      transparent: true,
      opacity: 0,
    })

    const img = new Mesh(geometry, this.material)
    this.inner.add(img)

    const box = new Object3D()
    box.position.z = 0.2
    box.position.y = h * -0.45

    const fontSize = this.stage.mq.matches ? 0.025 : 0.035
    const maxWidth = fontSize * (this.stage.mq.matches ? 35 : 25)

    if (caption || subline) {
      const text = new Text()
      box.add(text)

      text.material = new MeshBasicMaterial({ transparent: true, opacity: 0 })
      text.font = font
      text.text = `${caption}\n${subline || ''}`
      text.maxWidth = maxWidth
      text.fontSize = fontSize
      text.position.z = fontSize * 0.04
      text.position.y = fontSize * 0.2
      text.colorRanges = { 0: COLORS.lightBlue, [caption.length]: COLORS.blue }
      text.anchorX = '50%'
      text.anchorY = '50%'

      this.textMaterial = text.material

      text.addEventListener('synccomplete', () => {
        const size = text.geometry.boundingBox.getSize(new Vector3())
        this.boxMaterial = new MeshBasicMaterial({
          color: new Color(COLORS.darkBlue).convertLinearToSRGB(),
          transparent: true,
          opacity: 0,
        })
        const w = size.x + 0.04
        const h = size.y + 0.05
        const shape = getRoundedShape(w, h, 0.02)
        const geometry = new ShapeGeometry(shape, 8)
        geometry.translate(w * -0.5, h * -0.5, 0)
        const bg = new Mesh(geometry, this.boxMaterial)
        box.add(bg)
        box.position.z = 0.095

        this.inner.add(box)
      })

      text.sync()
    }
  }

  show() {
    this.object.visible = true
    this.elapsed.setTarget(1)
  }

  async hide(): Promise<void> {
    this.elapsed.setTarget(0)
  }

  resize(scale: number, angle: number) {
    this.endScale = scale
    this.object.rotation.y = angle
    this.inner.scale.set(scale, scale, scale)
  }

  update(delta: number) {
    this.elapsed.update(delta)
    const scale = this.endScale * this.elapsed.value
    this.inner.scale.set(scale, scale, scale)

    this.material.opacity = this.elapsed.value
    if (this.textMaterial) this.textMaterial.opacity = this.elapsed.value
    if (this.boxMaterial) this.boxMaterial.opacity = this.elapsed.value

    this.object.visible = this.elapsed.animating || this.elapsed.value > 0.01
  }
}
